map-view{ height: 450px; width: 100%; }





.poi-info-window, .gm-style .gm-ui-hover-effect{ display: none !important; }


.gm-style-iw{
  left: 0px !important;
  border-radius: $border-radius-base !important;
  padding: 0px !important;
  .gm-style-iw-d{// div:nth-of-type(1){
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }
}

.map-container{ display: inline-block; width: 100%; }
.minify{
  transition: all 0.3s ease-in-out;
  transform: scale(0.9);
}

.minifyStp, .minifyStr, .minify{
  .gm-bundled-control{ top: calc(100% - 160px) !important; }
}

.map-style{
  position: absolute;
  float: right;
  z-index: 20;
  margin-top: 15px;
  right: 30px;
  text-align: right;
  cursor: pointer;
}

.map-expand{
  cursor: pointer;
  position: fixed;
  right: 20px;
  text-align: right;
  top: 15px;
  width: 95%;
  z-index: 15;
}

.map-expandL{
  margin: 5px;
  cursor: pointer;
  top: 0px;
  left: 0px;
  z-index: 15;
  .direction{ .form-control{ background: white; } }
}

.map-expandR{
  position: fixed;
  display: inline-flex;
  width: 400px;
  margin: 5px;
  cursor: pointer;
  top: 3px;
  right: 70px;
  z-index: 15;
  button{ margin-left: 5px; }
}

.map-expandRA{ z-index: 15 !important; }
.pac-container{ z-index: 10000; }
.inline-middle, .map-expandL{ position: fixed !important; }
.night{ color: var(--psr-equis) !important; }

.expand{
  height: 100vh !important;
  left: 0px !important;
  position: fixed !important;
  right: 0px !important;
  transform: scale(1) !important;
  transition: 0.4s ease-in-out !important;
  top: 0px !important;
  width: 100% !important;
  z-index: 12 !important;

  .gm-bundled-control{ top: calc(100% - 160px) !important; }
}

#searchMap{
  min-width: 220px;
  @media screen and (max-width: 767px){ display: inline-block; width: auto; }
}

.gmnoprint{
  .gm-style-mtc{
    width: 55px;
    div:nth-of-type(1){
      font-size: 13px !important;
      height: 30px !important;
      padding: 0px 13px !important;
      label{margin-bottom: 0px !important; margin-top: 6px !important;}
    }
    div:nth-of-type(2){ padding-top: 10px !important; top: 30px !important; }
    button{
      font-size: 13px !important;
      padding: 0px !important;
      height: 32px !important;
      width: 100% !important;
    }
  }
}

.gm-style-cc{ div{  a{ color: var(--psr-text-color) !important; } } }

.gm-bundled-control{
  .gmnoprint{
    // height: 60px !important;
    background-color: white !important;
    border-radius: $border-radius-base;
    div:nth-of-type(1){
      background-color: transparent !important;
      box-shadow: 0px 1px 4px var(--psr-text-color);
      margin: 0px !important;
      width: 100% !important;
      button{
        // height: 30px !important;
        img{ height: 10px !important; }
      }
    }
  }
}
