// @import './app.variables.scss';

@mixin buttonVariant($color, $background, $border, $weight) {
  color: $color !important;
  background-color: $background;
  border-color: $border;
  font-family: $font-family-sans;
  font-weight: $weight;
  &:hover,
  &:focus,
  &:active,
  &.active{
    color: $color !important;
    background-color: darken($background, 5%);
    border-color: darken($border, 8%);
  }
  &:active, &.active{ background-image: none; }
  &.disabled,
  &[disabled],
  fieldset[disabled] &{
    &,
    &:hover,
    &:focus,
    &:active,
    &.active{
      background-color: $background;
      border-color: $border;
    }
  }
}

@mixin size($w, $h){ width: $w; height: $h; }
@mixin buttonColor($bg, $brd){ background-color: $bg; border: none;/*1px solid $brd;*/ }

@mixin fontStyles($color, $size, $family){
  color: $color !important;
  font-size: $size !important;
  font-family: $family;
}

@mixin autofill($background){
  &:-webkit-autofill{
    -webkit-box-shadow: 0 0 0px 1000px $background inset !important;
    box-shadow: 0 0 0px 1000px $background inset !important;
    transition: background-color 5000s ease-in-out 0s;
  }
  &:-moz-autofill{
    -moz-box-shadow: 0 0 0px 1000px $background inset !important;
    box-shadow: 0 0 0px 1000px $background inset !important;
    transition: background-color 5000s ease-in-out 0s;
  }
  &:-o-autofill{
    -o-box-shadow: 0 0 0px 1000px $background inset !important;
    box-shadow: 0 0 0px 1000px $background inset !important;
    transition: background-color 5000s ease-in-out 0s;
  }
  &:-khtml-autofill{
    -khtml-box-shadow: 0 0 0px 1000px $background inset !important;
    box-shadow: 0 0 0px 1000px $background inset !important;
    transition: background-color 5000s ease-in-out 0s;
  }
}

@mixin colorVariant($bg-color, $font-color, $link-color, $hover-color){
  background-color: $bg-color;
  color: $font-color;
  a{
    color: $link-color;
    &:hover{ color: $hover-color; }
  }
}

@mixin padder($padding){ padding-left: $padding; padding-right: $padding; }
