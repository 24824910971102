
.timepicker-container{
  background: var(--psr-primary-background);
  border: 1px solid var(--psr-input-border);
  border-radius: $border-radius-base;
  box-shadow: 5px 5px 15px #e9e9e9;
  position: absolute;
  right: 0!important;
  top: $input-size + 5px;
  transition: 0.09s all linear;
  width: 150px;
  z-index: 10;
  .timepicker-item{
    table{ width: 150px; margin: 0 auto; }
    .btn-link{ color: var(--psr-primary) !important; }
  }
}

#timepicker{
  #time-selected{
    cursor: pointer;
    &:focus{ border-color: var(--psr-primary) !important; }
  }
}

#tp1{ timepicker>table>tbody>tr:hover{ background-color: initial; }}
#tp1{
  timepicker>table>tbody>tr>td:nth-of-type(4),
  timepicker>table>tbody>tr>td:nth-of-type(5){ display: none; }
}
#tp1{ timepicker>table>tbody>tr:nth-of-type(2)>td>input{ pointer-events: none; width: 45px; } }
#tp1{ timepicker>table td{ padding: 1px 0px 15px 10px; } }
#tp1{ timepicker>table tr{ display: inherit; background-color: transparent !important; } }

#tp1{
  timepicker>table{
    position: fixed;
    margin: 0 auto;
    background-color: whitesmoke;
    border-radius: 8px;
    height: auto;
    top: 120px;
    left: 43%;
  }
}

.input-item{ #tp1{ timepicker{ table{ position: initial; } } }}
.input-group #time-selected{ font-size: 1.1250em !important; }
.input-group-btn{
  width: auto;
  button .fa{ font-size: initial; font-weight: normal; }
}

@media screen and (max-width: 390px){
  #tp1{ timepicker{ table{ top: 140px; left: 40%; } }}
}

.timepicker{
  #tp1{ timepicker{ table{ left: 30%; height: 30%; } }}
}

@media screen and (min-width: 991px){
  .timepicker{
    #tp1{ timepicker{ table{ left: 37%; height: 30%; } } }
  }
}


.ngx-mat-timepicker-dialog{
  .mat-dialog-container{
    // overflow: hidden;
    .mat-dialog-content{
      margin: 0 auto;
      .mat-timepicker{
        height: auto;
        padding: 0px;
        border: none;
      }
    }
    .mat-dialog-actions{
      margin-bottom: 0px;
    }
  }
}
