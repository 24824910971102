
// Controls

.form-control{
  background-color: var(--psr-font-color-primary);
  border: 1px solid var(--psr-input-border);
  border-radius: $border-radius-base;
  box-shadow: none;
  color: var(--psr-disabled-color);
  font-family: $font-family-sans;
  font-size: 0.8125em;
  height: $input-size !important;
  &:focus{
    border-color: var(--psr-secondary) !important;
    box-shadow: none !important;
  }
  &::-moz-placeholder{
    color: var(--psr-disabled-color) !important;
    font-weight: 400;
    opacity: 1;
  }
  &::-ms-input-placeholder{
    color: var(--psr-disabled-color) !important;
    font-weight: 400;
    opacity: 1;
  }
  &::-webkit-input-placeholder{
    color: var(--psr-disabled-color) !important;
    font-weight: 400;
    opacity: 1;
  }
}
.form-control[disabled]{
  background-color: var(--psr-app-aside-nav-head-bg);
  border-color: var(--psr-input-border);
  color: var(--psr-disabled-color);
}
.input-group{
  .form-control[readonly]{
    background-color: white;
    border-color: var(--psr-input-border);
    color: var(--psr-input-border);
  }
  .form-control[disabled]{
    background-color: var(--psr-app-aside-nav-head-bg);
    border-color: var(--psr-input-border);
    color: var(--psr-disabled-color);
  }
  .input-group-btn{
    button{
      border: 1px solid var(--psr-input-border) !important;
      border-radius: 0 $border-radius-base $border-radius-base 0;
      height: $input-size;
      &:hover{
        background-color: var(--psr-input-group-hover);
        i{ color: var(--psr-primary); }
      }
      &:active, &:focus{
        background-color: var(--psr-font-color-primary);
        color: var(--psr-primary);
      }
    }
  }
}
datepicker .well{
  background-color: #fff !important;
  .text-info{ color: var(--psr-secondary); }
  .btn-info.active{
    background-color: var(--psr-primary);
    border-color: var(--psr-input-border);
    span.text-info{ color: var(--psr-font-color-primary); }
  }
}

.datepicker-width{ max-width: 200px; }

@media screen and (max-width: 504px){
  .datepicker-date-range{ width: 45%; }
  .datepicker-since>#form-datepicker>.datepicker-container{
    left: 0 !important;
    right: auto !important;
  }
}

@media screen and (max-width: 400px){
  .datepicker-date-range, #form-datepicker{
    max-width: 100% !important;
    width: 100%;
  }
  .datepicker-since{
    margin-bottom: 3px;
    #form-datepicker>.datepicker-container{
      left: auto !important;
      right: 0 !important;
    }
  }
}

.form-control-header{
  background-color: var(--psr-font-color-primary);
  border-color: var(--psr-input-border);
  &:focus{
    border-color: var(--psr-secondary) !important;
    box-shadow: none !important;
  }
  &::-moz-placeholder{
    color: var(--psr-disabled-color) !important;
    font-weight: 400;
    opacity: 1;
  }
  &::-ms-input-placeholder{
    color: var(--psr-disabled-color) !important;
    font-weight: 400;
    opacity: 1;
  }
  &::-webkit-input-placeholder{
    color: var(--psr-disabled-color) !important;
    font-weight: 400;
    opacity: 1;
  }
}
// Radio Button

.radio-group{
  display: flex;
  flex-direction: row;
  input{
    &[type="radio"]{
      display: none;
      &:checked + label{
        &:before{
          background-color: var(--psr-radio-button-bg);
          box-shadow: inset white 0 0 0 4px;
        }
      }
    }
  }
  label{
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    font-weight: 400;
    font-size: 0.8125em;
    margin: 0 10px 0 0;
    &:before{
      border: 1px solid var(--psr-radio-button-border);
      border-radius: 50%;
      box-shadow: inset white 0 0 0 10px;
      box-sizing: border-box;
      content: '';
      height: 18px;
      width: 18px;
      margin: 0 5px 0 0;
      transition: all ease .200s;
    }
  }
}
// Selects

.filter-item.select-wrapper{
  &:after{
    right: 18px;
    top: 12px;
  }
}

.select-wrapper{
  position: relative;
  display: block;
  select{
    color: var(--psr-disabled-color);
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    padding: 6px 30px 6px 12px;
    position: relative;
    option:first{ color: red; }
  }
  &:after{
    content: "\f107";
    box-sizing: border-box;
    font-family: "Font Awesome 5 free";
    font-weight: 900;
    font-size: 1.2em;
    opacity: 0.6;
    pointer-events: none;
    position: absolute;
    right: 10px;
    top: 4px;
    z-index: 1;
  }
}
// Checkbox

.checkbox{
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-family: $font-family-sans;
  font-size: 0.8125em;
  font-weight: 400;
  position: relative;
  user-select: none;
  &:before{
    content: '';
    // background: var(--psr-checkbox-border);
    border: 1px solid var(--psr-checkbox-border);
    background: #fff !important;
    border-radius: $border-radius-base;
    position: relative;
    height: 17px;
    margin-right: 4px;
    transition: all .4s ease;
    width: 17px;
  }
  &:after{
    border-bottom: 2px solid var(--psr-checkbox-border);
    border-right: 2px solid var(--psr-checkbox-border);
    content: '';
    height: 10px;
    left: 6px;
    position: absolute;
    top: 3px;
    transform: rotate(25deg) scale(.2);
    transition: transform .2s ease;
    width: 6px;
  }
  &:hover{ &:before{ border: 1px solid #555; } }
  &.checkbox-secondary
    &:before{ background: white !important; border-radius: $border-radius-base; }
    &:after{ border-color: white !important; top: 4px; }
    &:hover{ &:before{ border-color: var(--psr-secondary) !important; }}
}

.checkbox-input:checked{
  +.checkbox{
    &:before{
      background: var(--psr-checkbox-border);
      border-color: var(--psr-checkbox-border);
    }
    &:after{
      border-color: var(--psr-font-color-primary);
      transform: rotate(40deg) scale(1);
    }
    &:hover{ &:before{ border: 1px solid #555; }}
    &.checkbox-secondary{
      &:before{
        background: #FFF !important;
        border-radius: $border-radius-base;
      }
      &:after{
        border-color: var(--psr-secondary) !important;
        top: 6px;
        left: 9px;
      }
      &:hover{ &:before{ border-color: var(--psr-secondary) !important; }}
    }
  }
}

// CHECKBOX-TOGGLE

.checkbox-toggle{
  .checkbox-input{
    display: none;
    &:checked + label{
      .off{ display: none; }
      .on{ display: inline-block; margin-left: 10px;}
    }
    &:checked + label, &:focus:checked + label{
      background-color: white;
      &:before, &:hover:before{
        left: 64%;
        top: 2px;
        background: var(--psr-radio-button-bg);
      }
    }
  }
  .checkbox-label{
    align-items: center;
    background-color: var(--psr-radio-button-border);
    border-radius: 25px;
    box-shadow: inset 0px 0px 1px rgba(0,0,0, .5);
    cursor: pointer;
    display: flex;
    height: 20px;
    margin: 0 auto;
    position: relative;
    transition: all .25s ease-in;
    width: 55px;
    &:before, &:hover:before{
      background: var(--psr-disabled-color);
      border-radius: 50%;
      content: '';
      height: 16px;
      left: 2px;
      position: absolute;
      top: 2px;
      transition: all .25s ease-in;
      width: 16px;
      z-index: 1;
    }
    .off, .on{
      background-color: transparent;
      color: var(--psr-font-color-primary);
      font-size: 13px;
      font-weight: 400;
    }
    .off{ color: var(--psr-disabled-color); margin-left: 25px; }
    .on{ color: var(--psr-mesage); display: none; }
  }
}

// checkbox styled

.checkbox-styled{
  display: block;
  margin: 0;
  input[type="checkbox"]{
    display: none;
    + span{
      border: 1px solid var(--psr-disabled-color);
      color: var(--psr-disabled-color);
      cursor: pointer;
      display: block;
      font-size: 0.875rem;
      font-weight: normal;
      margin: 5px 10px 0 0;
      padding: 15px;
      position: relative;
      text-align: center;
      width: 90px;
      transition: all 0.1s ease-in;
      &:hover{ border-color: var(--psr-primary); }
    }
    &:checked{
      + span{ border-color: var(--psr-primary); color: var(--psr-text-color); }
    }
  }
}

.dp-tickets{
  #form-datepicker{
    max-width: 200px;
    @media screen and (max-width: 1200px){ max-width: 100% !important; }
  }
}

.daterangepicker{
  .ranges{
    ul li{
      background: var(--psr-font-color-primary);
      border: 1px solid var(--psr-disabled-color);
      color: var(--psr-text-color) !important;
      &:hover{
        border-color: var(--psr-primary);
        background: var(--psr-primary) !important;
        color: #FFF !important;
      }
    }
    .active{
      border-color: var(--psr-primary);
      background: var(--psr-primary) !important;
      color: #FFF !important;
    }
    .range_inputs{
      .applyBtn{
        background: var(--psr-text-subtitle);
        border-color: var(--psr-text-subtitle);
        color: var(--psr-font-color-primary);
        &:hover{ background: lighten($text-subtitle, 10%); }
      }
    }
  }
  .input-mini{ border-color: var(--psr-primary) !important; }
  .calendar-table{
    td.in-range{ background: #F1F1F1 !important; }
    td.active, td.active:hover{ background: var(--psr-primary) !important; }
  }
}

.datepicker-w{ max-width: 230px; }

.fs-border{
  form-suggest{ .form-control-header{ border-color: var(--psr-input-border); } }
}

.is-invalid{ border: 1px solid var(--psr-equis); }
.invalid-feedback{ font-size: 100%; }

.input-group {
  .form-control, .input-group-btn{ z-index: 1 !important; }
}
