
.mat-dialog-container{ padding: 0px !important; }

.modal{
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0; // !important Para el modal del login
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: none;
  outline: 0;
  .btns-footer{
    button{ margin-right: 8px; }
    button:last-child{ margin-right: 0px; }
  }
}

.fade-modal {
  transition: opacity .15s linear;
  .modal-dialog{
    transition: transform .3s ease-out;
    transform: translateY(-25%);
  }
  .modal-sm {
    width: auto;
    max-width: 711px!important;
    position: relative;
  }
  .max-w-360{ max-width: 360px!important; }
  .max-w-480{ max-width: 480px!important; }
  .max-w-600{ max-width: 600px!important; }
  .max-w-900{ max-width: 900px!important; }
  .max-w-1080{ max-width: 1080px!important; }
  .max-w-1200{ max-width: 1200px!important; }
  .modal-content {
    box-shadow: 0 5px 15px rgba(0,0,0,.5);

    position: relative;
    background-color: var(--psr-font-color-primary);
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 6px;
    outline: 0;
    -o-animation-name: animatetop;
    -o-animation-duration: 0.4s;
    -moz-animation-name: animatetop;
    -moz-animation-duration: 0.4s;
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
    .modal-body{ padding: 0; }
  }

  .modal-fullscreen {
    max-width: 100% !important;
    width: 100%;
    height: 100%;
    margin: 0;
    .modal-content {
      height: 100%;
    }
  }

  @media screen and (min-width: 710px) {
    .modal-sm {
      width: 711px;
    }
  }
}

.fade-modal.in-modal {
  // opacity: 1;
  display: block;
  .modal-dialog {
    transform: translate(0);
  }
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}
