
/* Margins */

.margin-zero{ margin: 0 auto !important; }

.m-0{ margin: 0 !important;  }
.m-t-0{ margin-top: 0 !important;  }
.m-r-0{ margin-right: 0 !important;  }
.m-b-0{ margin-bottom: 0 !important;  }
.m-l-0{ margin-left: 0 !important;  }
.m-y-0{ margin-top: 0 !important; margin-bottom: 0 !important;  }
.m-x-0{ margin-right: 0 !important; margin-left: 0 !important;  }

.m-l-auto{
  margin-left: auto;
}

.m-1{ margin: $size-1 !important;  }
.m-t-1{ margin-top: $size-1 !important;  }
.m-r-1{ margin-right: $size-1 !important;  }
.m-b-1{ margin-bottom: $size-1 !important;  }
.m-l-1{ margin-left: $size-1 !important;  }
.m-y-1{ margin-top: $size-1 !important; margin-bottom: $size-1 !important;  }
.m-x-1{ margin-right: $size-1 !important; margin-left: $size-1 !important;  }

.m-2{ margin: $size-2 !important;  }
.m-t-2{ margin-top: $size-2 !important;  }
.m-r-2{ margin-right: $size-2 !important;  }
.m-b-2{ margin-bottom: $size-2 !important;  }
.m-l-2{ margin-left: $size-2 !important;  }
.m-y-2{ margin-top: $size-2 !important; margin-bottom: $size-2 !important;  }
.m-x-2{ margin-right: $size-2 !important; margin-left: $size-2 !important;  }

.m-m{ margin: $size-base !important;  }
.m-t{ margin-top: $size-base !important; }
.m-r{ margin-right: $size-base !important; }
.m-b{ margin-bottom: $size-base !important; }
.m-l{ margin-left: $size-base !important; }
.m-y{ margin-top: $size-base !important; margin-bottom: $size-base !important;  }
.m-x{ margin-right: $size-base !important; margin-left: $size-base !important;  }

.m-4{ margin: $size-4 !important;  }
.m-t-4{ margin-top: $size-4 !important;  }
.m-r-4{ margin-right: $size-4 !important; }
.m-b-4{ margin-bottom: $size-4 !important; }
.m-l-4{ margin-left: $size-4 !important;  }
.m-y-4{ margin-top: $size-4 !important; margin-bottom: $size-4 !important;  }
.m-x-4{ margin-right: $size-4 !important; margin-left: $size-4 !important;  }

.m-6{ margin: $size-6 !important;  }
.m-t-6{ margin-top: $size-6 !important;  }
.m-r-6{ margin-right: $size-6 !important; }
.m-b-6{ margin-bottom: $size-6 !important; }
.m-l-6{ margin-left: $size-6 !important;  }
.m-y-6{ margin-top: $size-6 !important; margin-bottom: $size-6 !important;  }
.m-x-6{ margin-right: $size-6 !important; margin-left: $size-6 !important;  }

.m-5{ margin: $size-5 !important;  }
.m-t-5{ margin-top: $size-5 !important;  }
.m-r-5{ margin-right: $size-5 !important; }
.m-b-5{ margin-bottom: $size-5 !important; }
.m-l-5{ margin-left: $size-5 !important;  }
.m-y-5{ margin-top: $size-5 !important; margin-bottom: $size-5 !important;  }
.m-x-5{ margin-right: $size-5 !important; margin-left: $size-5 !important;  }

.m-8{ margin: $size-8 !important;  }
.m-t-8{ margin-top: $size-8 !important;  }
.m-r-8{ margin-right: $size-8 !important; }
.m-b-8{ margin-bottom: $size-8 !important; }
.m-l-8{ margin-left: $size-8 !important;  }
.m-y-8{ margin-top: $size-8 !important; margin-bottom: $size-8 !important;  }
.m-x-8{ margin-right: $size-8 !important; margin-left: $size-8 !important;  }

.m-10{ margin: $size-10 !important; }
.m-t-10{ margin-top: $size-10 !important; }
.m-r-10{ margin-right: $size-10 !important; }
.m-b-10{ margin-bottom: $size-10 !important; }
.m-l-10{ margin-left: $size-10 !important; }
.m-y-10{ margin-top: $size-10 !important; margin-bottom: $size-10 !important;  }
.m-x-10{ margin-right: $size-10 !important; margin-left: $size-10 !important;  }

.m-12{ margin: $size-12 !important; }
.m-t-12{ margin-top: $size-12 !important; }
.m-r-12{ margin-right: $size-12 !important; }
.m-b-12{ margin-bottom: $size-12 !important; }
.m-l-12{ margin-left: $size-12 !important; }
.m-y-12{ margin-top: $size-12 !important; margin-bottom: $size-12 !important;  }
.m-x-12{ margin-right: $size-12 !important; margin-left: $size-12 !important;  }

.m-16{ margin: $size-16 !important; }
.m-t-16{ margin-top: $size-16 !important; }
.m-r-16{ margin-right: $size-16 !important; }
.m-b-16{ margin-bottom: $size-16 !important; }
.m-l-16{ margin-left: $size-16 !important; }
.m-y-16{ margin-top: $size-16 !important; margin-bottom: $size-16 !important;  }
.m-x-16{ margin-right: $size-16 !important; margin-left: $size-16 !important;  }

.m-20{ margin: $size-20 !important; }
.m-t-20{ margin-top: $size-20 !important; }
.m-r-20{ margin-right: $size-20 !important; }
.m-b-20{ margin-bottom: $size-20 !important; }
.m-l-20{ margin-left: $size-20 !important; }
.m-y-20{ margin-top: $size-20 !important; margin-bottom: $size-20 !important;  }
.m-x-20{ margin-right: $size-20 !important; margin-left: $size-20 !important;  }

.m-25{ margin: $size-25 !important; }
.m-t-25{ margin-top: $size-25 !important; }
.m-r-25{ margin-right: $size-25 !important; }
.m-b-25{ margin-bottom: $size-25 !important; }
.m-l-25{ margin-left: $size-25 !important; }
.m-y-25{ margin-top: $size-25 !important; margin-bottom: $size-25 !important;  }
.m-x-25{ margin-right: $size-25 !important; margin-left: $size-25 !important;  }

.m-30{ margin: $size-30 !important;  }
.m-t-30{ margin-top: $size-30 !important;  }
.m-r-30{ margin-right: $size-30 !important;  }
.m-b-30{ margin-bottom: $size-30 !important;  }
.m-l-30{ margin-left: $size-30 !important;  }
.m-y-30{ margin-top: $size-30 !important; margin-bottom: $size-30 !important;  }
.m-x-30{ margin-right: $size-30 !important; margin-left: $size-30 !important;  }

.m-40{ margin: $size-40 !important; }
.m-t-40{ margin-top: $size-40 !important; }
.m-r-40{ margin-right: $size-40 !important; }
.m-b-40{ margin-bottom: $size-40 !important; }
.m-l-40{ margin-left: $size-40 !important; }
.m-y-40{ margin-top: $size-40 !important; margin-bottom: $size-40 !important;  }
.m-x-40{ margin-right: $size-40 !important; margin-left: $size-40 !important;  }

.m-45{ margin: $size-45 !important; }
.m-t-45{ margin-top: $size-45 !important; }
.m-r-45{ margin-right: $size-45 !important; }
.m-b-45{ margin-bottom: $size-45 !important; }
.m-l-45{ margin-left: $size-45 !important; }
.m-y-45{ margin-top: $size-45 !important; margin-bottom: $size-45 !important;  }
.m-x-45{ margin-right: $size-45 !important; margin-left: $size-45 !important;  }

.m-50{ margin: $size-50 !important; }
.m-t-50{ margin-top: $size-50 !important; }
.m-r-50{ margin-right: $size-50 !important; }
.m-b-50{ margin-bottom: $size-50 !important; }
.m-l-50{ margin-left: $size-50 !important; }
.m-y-50{ margin-top: $size-50 !important; margin-bottom: $size-50 !important;  }
.m-x-50{ margin-right: $size-50 !important; margin-left: $size-50 !important;  }

.m-60{ margin: $size-60 !important; }
.m-t-60{ margin-top: $size-60 !important; }
.m-r-60{ margin-right: $size-60 !important; }
.m-b-60{ margin-bottom: $size-60 !important; }
.m-l-60{ margin-left: $size-60 !important; }
.m-y-60{ margin-top: $size-60 !important; margin-bottom: $size-60 !important;  }
.m-x-60{ margin-right: $size-60 !important; margin-left: $size-60 !important;  }

.m-80{ margin: $size-80 !important; }
.m-t-80{ margin-top: $size-80 !important; }
.m-r-80{ margin-right: $size-80 !important; }
.m-b-80{ margin-bottom: $size-80 !important; }
.m-l-80{ margin-left: $size-80 !important; }
.m-y-80{ margin-top: $size-80 !important; margin-bottom: $size-80 !important;  }
.m-x-80{ margin-right: $size-80 !important; margin-left: $size-80 !important;  }

.m-100{ margin: $size-100 !important; }
.m-t-100{ margin-top: $size-100 !important; }
.m-r-100{ margin-right: $size-100 !important; }
.m-b-100{ margin-bottom: $size-100 !important; }
.m-l-100{ margin-left: $size-100 !important; }
.m-y-100{ margin-top: $size-100 !important; margin-bottom: $size-100 !important;  }
.m-x-100{ margin-right: $size-100 !important; margin-left: $size-100 !important;  }

.o-m-t-xmd{ margin-top: -($size-30) !important; }
.o-m-r-xmd{ margin-right: -($size-30) !important; }
.o-m-b-xmd{ margin-bottom: -($size-30) !important; }
.o-m-l-xmd{ margin-left: -($size-30) !important; }


.m-t-55{ margin-top: 55px !important; }
.m-b-m35{ margin-bottom: -35px !important; }

.mt--50{ margin-top: -50px !important; }
/* paddings */

.p-0{ padding: 0 !important; }
.p-l-0{ padding-left: 0 !important; }
.p-r-0{ padding-right: 0 !important; }
.p-t-0{ padding-top: 0 !important; }
.p-b-0{ padding-bottom: 0 !important; }
.p-y-0{ padding-top: 0 !important; padding-bottom: 0 !important;  }
.p-x-0{ padding-right: 0 !important; padding-left: 0 !important;  }

.p-1{ padding: $size-1 !important; }
.p-t-1{ padding-top: $size-1 !important; }
.p-r-1{ padding-right: $size-1 !important; }
.p-b-1{ padding-bottom: $size-1 !important; }
.p-l-1{ padding-left: $size-1 !important; }
.p-y-1{ padding-top: $size-1 !important; padding-bottom: $size-1 !important;  }
.p-x-1{ padding-right: $size-1 !important; padding-left: $size-1 !important;  }

.p-2{ padding: $size-2 !important; }
.p-t-2{ padding-top: $size-2 !important; }
.p-r-2{ padding-right: $size-2 !important; }
.p-b-2{ padding-bottom: $size-2 !important; }
.p-l-2{ padding-left: $size-2 !important; }
.p-y-2{ padding-top: $size-2 !important; padding-bottom: $size-2 !important;  }
.p-x-2{ padding-right: $size-2 !important; padding-left: $size-2 !important;  }

.p-4{ padding: $size-4 !important; }
.p-t-4{ padding-top: $size-4 !important; }
.p-r-4{ padding-right: $size-4 !important; }
.p-b-4{ padding-bottom: $size-4 !important; }
.p-l-4{ padding-left: $size-4 !important; }
.p-y-4{ padding-top: $size-4 !important; padding-bottom: $size-4 !important;  }
.p-x-4{ padding-right: $size-4 !important; padding-left: $size-4 !important;  }

.p-6{ padding: $size-6 !important; }
.p-t-6{ padding-top: $size-6 !important; }
.p-r-6{ padding-right: $size-6 !important; }
.p-b-6{ padding-bottom: $size-6 !important; }
.p-l-6{ padding-left: $size-6 !important; }
.p-y-6{ padding-top: $size-6 !important; padding-bottom: $size-6 !important;  }
.p-x-6{ padding-right: $size-6 !important; padding-left: $size-6 !important;  }

.p-5{ padding: $size-5 !important; }
.p-t-5{ padding-top: $size-5 !important; }
.p-r-5{ padding-right: $size-5 !important; }
.p-b-5{ padding-bottom: $size-5 !important; }
.p-l-5{ padding-left: $size-5 !important; }
.p-y-5{ padding-top: $size-5 !important; padding-bottom: $size-5 !important;  }
.p-x-5{ padding-right: $size-5 !important; padding-left: $size-5 !important;  }

.p-8{ padding: $size-8 !important; }
.p-t-8{ padding-top: $size-8 !important; }
.p-r-8{ padding-right: $size-8 !important; }
.p-b-8{ padding-bottom: $size-8 !important; }
.p-l-8{ padding-left: $size-8 !important; }
.p-y-8{ padding-top: $size-8 !important; padding-bottom: $size-8 !important;  }
.p-x-8{ padding-right: $size-8 !important; padding-left: $size-8 !important;  }

.p-10{ padding: $size-10 !important; }
.p-t-10{ padding-top: $size-10 !important; }
.p-r-10{ padding-right: $size-10 !important; }
.p-b-10{ padding-bottom: $size-10 !important; }
.p-l-10{ padding-left: $size-10 !important; }
.p-y-10{ padding-top: $size-10 !important; padding-bottom: $size-10 !important;  }
.p-x-10{ padding-right: $size-10 !important; padding-left: $size-10 !important;  }

.p-12{ padding: $size-12 !important; }
.p-t-12{ padding-top: $size-12 !important; }
.p-r-12{ padding-right: $size-12 !important; }
.p-b-12{ padding-bottom: $size-12 !important; }
.p-l-12{ padding-left: $size-12 !important; }
.p-y-12{ padding-top: $size-12 !important; padding-bottom: $size-12 !important;  }
.p-x-12{ padding-right: $size-12 !important; padding-left: $size-12 !important;  }

.p-16{ padding: $size-16 !important; }
.p-t-16{ padding-top: $size-16 !important; }
.p-r-16{ padding-right: $size-16 !important; }
.p-b-16{ padding-bottom: $size-16 !important; }
.p-l-16{ padding-left: $size-16 !important; }
.p-y-16{ padding-top: $size-16 !important; padding-bottom: $size-16 !important;  }
.p-x-16{ padding-right: $size-16 !important; padding-left: $size-16 !important;  }

.p-18{ padding: $size-18 !important; }
.p-t-18{ padding-top: $size-18 !important; }
.p-r-18{ padding-right: $size-18 !important; }
.p-b-18{ padding-bottom: $size-18 !important; }
.p-l-18{ padding-left: $size-18 !important; }
.p-y-18{ padding-top: $size-18 !important; padding-bottom: $size-18 !important;  }
.p-x-18{ padding-right: $size-18 !important; padding-left: $size-18 !important;  }

.p-20{ padding: $size-20 !important; }
.p-t-20{ padding-top: $size-20 !important; }
.p-r-20{ padding-right: $size-20 !important; }
.p-b-20{ padding-bottom: $size-20 !important; }
.p-l-20{ padding-left: $size-20 !important; }
.p-y-20{ padding-top: $size-20 !important; padding-bottom: $size-20 !important;  }
.p-x-20{ padding-right: $size-20 !important; padding-left: $size-20 !important;  }

.p-25{ padding: $size-25 !important; }
.p-t-25{ padding-top: $size-25 !important; }
.p-r-25{ padding-right: $size-25 !important; }
.p-b-25{ padding-bottom: $size-25 !important; }
.p-l-25{ padding-left: $size-25 !important; }
.p-y-25{ padding-top: $size-25 !important; padding-bottom: $size-25 !important;  }
.p-x-25{ padding-right: $size-25 !important; padding-left: $size-25 !important;  }

.p-m{ padding: $size-base !important; }
.p-t{ padding-top: $size-base !important; }
.p-r{ padding-right: $size-base !important; }
.p-b{ padding-bottom: $size-base !important; }
.p-l{ padding-left: $size-base !important; }
.p-y{ padding-top: $size-base !important; padding-bottom: $size-base !important;  }
.p-x{ padding-right: $size-base !important; padding-left: $size-base !important;  }

.p-30{ padding: $size-30 !important; }
.p-t-30{ padding-top: $size-30 !important; }
.p-r-30{ padding-right: $size-30 !important; }
.p-b-30{ padding-bottom: $size-30 !important; }
.p-l-30{ padding-left: $size-30 !important; }
.p-y-30{ padding-top: $size-30 !important; padding-bottom: $size-30 !important;  }
.p-x-30{ padding-right: $size-30 !important; padding-left: $size-30 !important;  }

.p-40{ padding: $size-40 !important; }
.p-t-40{ padding-top: $size-40 !important; }
.p-r-40{ padding-right: $size-40 !important; }
.p-b-40{ padding-bottom: $size-40 !important; }
.p-l-40{ padding-left: $size-40 !important; }
.p-y-40{ padding-top: $size-40 !important; padding-bottom: $size-40 !important;  }
.p-x-40{ padding-right: $size-40 !important; padding-left: $size-40 !important;  }

.p-50{ padding: $size-50 !important; }
.p-t-50{ padding-top: $size-50 !important; }
.p-r-50{ padding-right: $size-50 !important; }
.p-b-50{ padding-bottom: $size-50 !important; }
.p-l-50{ padding-left: $size-50 !important; }
.p-y-50{ padding-top: $size-50 !important; padding-bottom: $size-50 !important;  }
.p-x-50{ padding-right: $size-50 !important; padding-left: $size-50 !important;  }

.p-80{ padding: $size-80 !important; }
.p-t-80{ padding-top: $size-80 !important; }
.p-r-80{ padding-right: $size-80 !important; }
.p-b-80{ padding-bottom: $size-80 !important; }
.p-l-80{ padding-left: $size-80 !important; }
.p-y-80{ padding-top: $size-80 !important; padding-bottom: $size-80 !important;  }
.p-x-80{ padding-right: $size-80 !important; padding-left: $size-80 !important;  }

.padder-10{ padding-left: $size-10 !important;  padding-right: $size-10 !important; }
.padder-md{ padding-left: $size-base !important;  padding-right: $size-base !important; }
.padder-50{ padding-left: $size-50 !important;  padding-right: $size-50 !important; }

/* width */

.w-5{ width: 5% !important; }
.w-10{ width: 10% !important; }
.w-15{ width: 15% !important; }
.w-20{ width: 20% !important; }
.w-25{ width: 25% !important; }
.w-30{ width: 30% !important; }
.w-35{ width: 35% !important; }
.w-40{ width: 40% !important; }
.w-45{ width: 45% !important; }
.w-50{ width: 50% !important; }
.w-55{ width: 55% !important; }
.w-60{ width: 60% !important; }
.w-65{ width: 65% !important; }
.w-70{ width: 70% !important; }
.w-75{ width: 75% !important; }
.w-80{ width: 80% !important; }
.w-85{ width: 85% !important; }
.w-90{ width: 90% !important; }
.w-95{ width: 95% !important; }
.w-100 { width: 100% !important; }
.w-100vw{ width: 100vw !important; }
.w-auto{ width: auto !important;}
.w-fit-content{ width: fit-content !important; }
.w-inherit { width: inherit !important; }

.w-px-1{ width: 1px !important; }
.w-px-20{ width: 20px !important; }
.w-px-22{ width: 22px !important; }
.w-px-25{ width: 25px !important; }
.w-px-30{ width: 30px !important; }
.w-px-34{ width: 34px !important; }
.w-px-35{ width: 35px !important; }
.w-px-40{ width: 40px !important; }
.w-px-45{ width: 45px !important; }
.w-px-50{ width: 50px !important; }
.w-px-60{ width: 60px !important; }
.w-px-62{ width: 62px !important; }
.w-px-70{ width: 70px !important; }
.w-px-75{ width: 75px !important; }
.w-px-80{ width: 80px !important; }
.w-px-90{ width: 90px !important; }
.w-px-100{ width: 100px !important; }
.w-px-110{ width: 110px !important; }
.w-px-120{ width: 120px !important; }
.w-px-130{ width: 130px !important; }
.w-px-135{ width: 135px !important; }
.w-px-140{ width: 140px !important; }
.w-px-145{ width: 145px !important; }
.w-px-150{ width: 150px !important; }
.w-px-155{ width: 155px !important; }
.w-px-160{ width: 160px !important; }
.w-px-180{ width: 180px !important; }
.w-px-190{ width: 190px !important; }
.w-px-200{ width: 200px !important; }
.w-px-220{ width: 220px !important; }
.w-px-250{ width: 250px !important; }
.w-px-260{ width: 260px !important; }
.w-px-290{ width: 290px !important; }
.w-px-300{ width: 300px !important; }
.w-px-370{ width: 370px !important; }
.w-px-400{ width: 400px !important; }
.w-px-425{ width: 425px !important; }
.w-px-450{ width: 450px !important; }
.w-px-500{ width: 500px !important; }

.max-w-100{ max-width: 100% !important; }
.max-w-90px{ max-width: 90px !important; }
.max-w-100px{ max-width: 100px !important; }
.max-w-130px{ max-width: 130px !important; }
.max-w-180px{ max-width: 180px !important; }
.max-w-220px{ max-width: 220px !important; }
.max-w-230px{ max-width: 230px !important; }
.max-w-300px{ max-width: 300px !important; }
.max-w-360{ max-width: 360px !important; }
.max-w-500px{ max-width: 500px !important; }
.max-w-900px{ max-width: 900px !important; }

.max-w-col3{ max-width: 25% !important; }

.min-w-auto{ min-width: auto !important; }
.min-w-25px{ min-width: 25px !important; }
.min-w-35px{ min-width: 35px !important; }
.min-w-90px{ min-width: 90px !important; }
.min-w-120px{ min-width: 120px !important; }
.min-w-130px{ min-width: 130px !important; }
.min-w-150px{ min-width: 150px !important; }
.min-w-175px{ min-width: 175px !important; }
.min-w-220px{ min-width: 220px !important; }
.min-w-250px{ min-width: 250px !important; }
.min-w-300px{ min-width: 300px !important; }
.min-w-320px{ min-width: 320px !important; }
.min-w-360px{ min-width: 360px !important; }
.min-w-500px{ min-width: 500px !important; }
.min-w-600px{ min-width: 600px !important; }
.min-w-970px{ min-width: 970px !important; }
.min-w-1200px{ min-width: 1200px !important; }

.min-max-content{ min-width: max-content !important; }
.min-w-fit{ min-width: fit-content !important; }

/* Height */

.h-5{  height: 5%  !important; }
.h-10{ height: 10% !important; }
.h-15{ height: 15% !important; }
.h-20{ height: 20% !important; }
.h-25{ height: 25% !important; }
.h-30{ height: 30% !important; }
.h-35{ height: 35% !important; }
.h-40{ height: 40% !important; }
.h-45{ height: 45% !important; }
.h-50{ height: 50% !important; }
.h-55{ height: 55% !important; }
.h-60{ height: 60% !important; }
.h-65{ height: 65% !important; }
.h-70{ height: 70% !important; }
.h-75{ height: 75% !important; }
.h-80{ height: 80% !important; }
.h-85{ height: 85% !important; }
.h-90{ height: 90% !important; }
.h-95{ height: 95% !important; }
.h-100 { height: 100%  !important; }
.h-auto{ height: auto !important;}
.h-100vh{ height: 100vh !important; }

.h-px-20{ height: 20px !important; }
.h-px-22{ height: 22px !important; }
.h-px-25{ height: 25px !important; }
.h-px-30{ height: 30px !important; }
.h-px-32{ height: 32px !important; }
.h-px-34{ height: 34px !important; }
.h-px-35{ height: 35px !important; }
.h-px-40{ height: 40px !important; }
.h-px-45{ height: 45px !important; }
.h-px-50{ height: 50px !important; }
.h-px-55{ height: 55px !important; }
.h-px-60{ height: 60px !important; }
.h-px-67{ height: 67px !important; }
.h-px-70{ height: 70px !important; }
.h-px-75{ height: 75px !important; }
.h-px-80{ height: 80px !important; }
.h-px-90{ height: 90px !important; }
.h-px-100{ height: 100px !important; }
.h-px-110{ height: 110px !important; }
.h-px-130{ height: 130px !important; }
.h-px-135{ height: 135px !important; }
.h-px-140{ height: 140px !important; }
.h-px-145{ height: 145px !important; }
.h-px-150{ height: 150px !important; }
.h-px-155{ height: 155px !important; }
.h-px-190{ height: 190px !important; }
.h-px-200{ height: 200px !important; }
.h-px-220{ height: 220px !important; }
.h-px-250{ height: 250px !important; }
.h-px-260{ height: 260px !important; }
.h-px-290{ height: 290px !important; }
.h-px-300{ height: 300px !important; }
.h-px-400{ height: 400px !important; }
.h-px-425{ height: 425px !important; }

.min-h-100px{ min-height: 100px !important; }
.min-h-320px{ min-height: 320px !important; }
.min-h-500{ min-height: 500px !important; }
.max-h-40px{ max-height: 40px !important; }
.max-h-100{ max-height: 100px !important; }
.max-h-200px{ max-height: 200px !important; }
.max-h-220px{ max-height: 220px !important; }
.max-h-250px{ max-height: 250px !important; }
.max-h-300px{ max-height: 300px !important; }
.max-h-400px{ max-height: 400px !important; }
.max-h-600px{ max-height: 600px !important; }

.empty-auto{ height: 100% !important; }

/* LINE-HEIGHT */

.l-height-normal{ line-height: normal !important; }
.line-height-material { line-height: 59px !important; }

/* FLOAT */

.f-left{ float: left !important; }
.f-right{ float: right !important; }
.f-none{ float: none !important; }

/* BORDER */

.n-radius{ border-radius: 0px !important; }
.radius-50{ border-radius: 50% !important; }
.radius-base{ border-radius: $border-radius-base !important; }
.radius-8{ border-radius: 8px !important; }
.radius-16{ border-radius: 16px !important; }
.radius-20{ border-radius: 20px !important; }
.radius-25{ border-radius: 25px !important; }
.radius-100{ border-radius: 100px !important; }
.radius-yr-5{
  border-top-right-radius: $border-radius-base !important;
  border-bottom-right-radius: $border-radius-base !important;
}
.radius-t-4{ border-radius: 4px 4px 0px 0px !important; }

.border-blue { border: 1px solid var(--psr-check-view) !important; }
.border-blue-hover{
  &:hover{
    border: 1px solid var(--psr-check-view) !important;
  }
}
.border-purple{ border: 1px solid var(--psr-radio-button-bg); }
.border-purple-opaque{ border: 1px solid var(--psr-purple-opaque) }
.border-DB{ border: 1px solid var(--psr-input-border) !important; }
.border-r{ border-right: 1px solid var(--psr-input-border); }
.border-l{ border-left: 1px solid var(--psr-input-border); }
.border-t{ border-top: 1px solid var(--psr-input-border) !important; }
.border-b{ border-bottom: 1px solid var(--psr-input-border) !important; }
.border-b-color-text{ border-bottom: 1px solid var(--psr-text-color); }

.n-border{ border: none !important; }
.n-t-border{ border-top: none !important; }
.n-r-border{ border-right: none !important; }
.n-b-border{ border-bottom: none !important; }
.n-l-border{ border-left: none !important; }

.n-shadow{ box-shadow: none !important; }
.shadow-nav{ box-shadow: 3px 6px 6px var(--psr-shadow-box) !important; }
.shadow-box{ box-shadow: 0px 3px 6px var(--psr-light) !important; }
.shadow-expand{ box-shadow: 0px 3px 6px var(--psr-input-border-29); }
.shadow-top { box-shadow: 0 -6px 5px -5px var(--psr-light) !important; }

/* FLEX */

.flex-container{
  display: flex;
  &.row{ flex-flow: row nowrap; }
  &.column{ flex-flow: column nowrap; }
}

/* divider line */

.divider, .divider2, .dividerCCC{ height: 1px; overflow: hidden; }
.divider{ border: 1px dotted var(--psr-app-aside-nav-head-bg); }
.divider2{ border: 1px solid var(--psr-input-border); }
.dividerCCC{ border: 1px solid var(--psr-ccc); }

.divider-subticket{
  height: 1px;
  background-color: var(--psr-text-color);
}

.divider-ticket{
  height: 3px;
  background-color: var(--psr-text-color);
}

/*hide*/

.overflow-hidden{
  height: 0!important;
  overflow: hidden;
}

.no-overflow { overflow: hidden !important; }
.no-overflow-y { overflow-y: hidden !important; }
.no-overflow-x { overflow-x: hidden !important; }

.overflow-y { overflow-y: auto !important; }
.overflow-x { overflow-x: auto !important; }

.transparent{
  color: transparent !important;
  background: transparent !important;
}

.inline-middle{
  display: inline-block !important;
  vertical-align: middle;
}

@media (min-width: $screen-xlg){
  .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4,
  .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8,
  .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12 {
    float: left;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
  .col-xl-12{ width: 100%; }
  .col-xl-11{ width: 91.66666667%; }
  .col-xl-10{ width: 83.33333333%; }
  .col-xl-9-5{ width: 80.333333%; }
  .col-xl-9{ width: 75%; }
  .col-xl-8{ width: 66.66666667%; }
  .col-xl-7{ width: 58.33333333%; }
  .col-xl-6{ width: 50%; }
  .col-xl-5{ width: 41.66666667%; }
  .col-xl-4{ width: 33.33333333%; }
  .col-xl-3{ width: 25%; }
  .col-xl-2-5{ width: 19.666667%; }
  .col-xl-2{ width: 16.66666667%; }
  .col-xl-1{ width: 8.33333333%; }
}
