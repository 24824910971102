
// Text-transform
.upper{ text-transform: uppercase !important; }
.lower{ text-transform: lowercase !important; }
.capi{ text-transform: capitalize !important; }
.transf-none{ text-transform: none !important; }

// Text-deco
.n-underline{ text-decoration: none !important; }
.underline{ text-decoration: underline !important; }

// Font-sizes EM/16px
.f8{ font-size: 0.5em !important; }
.f9{ font-size: 0.5625em !important; }
.f10{ font-size: 0.625em !important; }
.f11{ font-size: 0.6875em !important; }
.f12{ font-size: 0.75em !important; }
.f13{ font-size: 0.8125em !important; }
.f14{ font-size: 0.875em !important; }
.f15{ font-size: 0.9375em !important; }
.f16{ font-size: 1em !important; }
.f17{ font-size: 1.0625em !important; }
.f18{ font-size: 1.125em !important; }
.f19{ font-size: 1.1875em !important; }
.f20{ font-size: 1.25em !important; }

.f25{ font-size: 1.5625em !important; }
.f24{ font-size: 1.5em !important; }
.f30{ font-size: 1.875em !important; }
.f40{ font-size: 2.5em !important; }
.f50{ font-size: 3.125em !important; }

// Font-weight
.fw-bold{ font-weight: bold !important; }
.fw-lighter{ font-weight: lighter !important; }
.fw-normal{ font-weight: normal !important; }

.fw-400{ font-weight: 400 !important; }
.fw-600{ font-weight: 600 !important; }

// Cursor / pointer
.n-pointer-event{ pointer-events: none !important; }
.pointer{ cursor: pointer !important; }
.n-allowed{ cursor: not-allowed !important; pointer-events: none !important; }

// POSITIONS
.absolute{ position: absolute !important; }
.relative{ position: relative !important; }
.fixed{ position: fixed !important; }

// TOP - BOTTOM - RIGHT - LEFT
.top-0{ top: 0px !important; }
.right-0{ right: 0px !important; }
.bottom-0{ bottom: 0px !important; }
.left-0{ left: 0px !important; }

.top-6{ top: 6px !important; }
.right-6{ right: 6px !important; }
.top-8{ top: 8px !important; }
.left-10{ left: 10px !important; }
.top-16{ top: 16px !important; }
.right-16{ right: 16px !important; }
.bottom-16 { bottom: 16px !important; }
.top-20{ top: 20px !important; }
.top-22{ top: 22px !important; }
.right-22{ right: 22px !important; }
.top-26{ top: 26px !important; }
.top-30{ top: 30px !important; }
.right-32{ right: 32px !important; }

// VERTICAL-ALIGN
.v-base{ vertical-align: baseline !important; }
.v-b{ vertical-align: middle !important; }
.v-bottom{ vertical-align: bottom !important; }
.v-top{ vertical-align: top !important; }
.v-text-bottom{ vertical-align: text-bottom !important; }
.v-text-top{ vertical-align: text-top !important; }

// LIST-STYLE

.n-list-style{ list-style: none !important; }

.f-italic{ font-style: italic !important; }

// OTHER

.z-index-10{ z-index: 10 !important; }
.z-index-100{ z-index: 100 !important; }
.z-index-1001{ z-index: 1001 !important; }
.n-opacity{ opacity: 0 !important; }
.opacity-37{ opacity: 0.37 !important; }

.opacity-half-camera{ background: linear-gradient(transparent 50%, var(--psr-camera-opac) 50%); }
