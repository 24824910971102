/* You can add global styles to this file, and also import other style files */

@import 'src/theme/app.variables.scss';
@import 'src/theme/app.mixins.scss';
@import 'src/theme/app.reset.scss';
@import 'src/theme/app.template.scss';
@import 'src/theme/app.utilities.scss';
@import 'src/theme/app.colors.scss';
@import 'src/theme/app.forms.scss';
@import 'src/theme/app.buttons.scss';
@import 'src/theme/app.inputs.scss';
@import 'src/theme/app.maps.scss';
@import 'src/theme/app.picker.scss';
@import 'src/theme/app.modal.scss';
@import 'src/theme/app.loader.scss';
@import 'src/theme/app.bus.scss';
@import 'src/theme/app.timepicker2.scss';
@import 'src/theme/app.ticket.scss';
@import 'src/theme/app.materialStyle.scss';
@import 'src/theme/app.print.scss';
@import 'src/theme/app.container-fullscreen.scss';

@import 'src/theme/flex.scss';
@import 'src/theme/fonts.scss';
@import 'src/theme/grid.scss';

@import '~handsontable/dist/handsontable.full.css';

@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';

@import '~@ctrl/ngx-emoji-mart/picker';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
