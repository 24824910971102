.flex{  display: flex !important; }
.block{ display: block !important; }
.display-inline{ display: inline !important; }
.inline-block{ display: inline-block !important; }
.inline-flex{ display: inline-flex !important; }
.inline-table{ display: inline-table !important; }
.inline-grid{ display: inline-grid !important; }
.d-table{ display: table !important; }
.grid{ display: grid !important; }
.none{ display: none !important; }
.contents{ display: contents !important; }

.v-hide{ visibility: hidden !important; }

.flex-flow-row       { flex-flow: row nowrap !important; }
.flex-column-nowrap  { flex-flow: column nowrap !important; }

.flex-row            { flex-direction: row !important; }
.flex-column         { flex-direction: column !important; }
.flex-row-reverse    { flex-direction: row-reverse !important; }
.flex-column-reverse { flex-direction: column-reverse !important; }

.flex-wrap         { flex-wrap: wrap !important; }
.flex-nowrap       { flex-wrap: nowrap !important; }
.flex-wrap-reverse { flex-wrap: wrap-reverse !important; }
.flex-fill         { flex: 1 1 auto !important; }
.flex-grow-0       { flex-grow: 0 !important; }
.flex-grow-1       { flex-grow: 1 !important; }
.flex-shrink-0     { flex-shrink: 0 !important; }
.flex-shrink-1     { flex-shrink: 1 !important; }

.justify-content-start   { justify-content: flex-start !important; }
.justify-content-end     { justify-content: flex-end !important; }
.justify-content-center  { justify-content: center !important; }
.justify-content-between { justify-content: space-between !important; }
.justify-content-around  { justify-content: space-around !important; }
.justify-content-baseline{ justify-content: baseline !important; }

.align-items-initial  { align-items: initial !important; }
.align-items-start    { align-items: flex-start !important; }
.align-items-end      { align-items: flex-end !important; }
.align-items-center   { align-items: center !important; }
.align-items-baseline { align-items: baseline !important; }
.align-items-stretch  { align-items: stretch !important; }

.align-content-start   { align-content: flex-start !important; }
.align-content-end     { align-content: flex-end !important; }
.align-content-center  { align-content: center !important; }
.align-content-between { align-content: space-between !important; }
.align-content-around  { align-content: space-around !important; }
.align-content-stretch { align-content: stretch !important; }

.align-self-auto     { align-self: auto !important; }
.align-self-start    { align-self: flex-start !important; }
.align-self-end      { align-self: flex-end !important; }
.align-self-center   { align-self: center !important; }
.align-self-baseline { align-self: baseline !important; }
.align-self-stretch  { align-self: stretch !important; }
.align-self-normal   { align-self: normal !important; }
