.progress-spinner{
  background: rgba(0, 0, 0, 0.3);
  mat-spinner{
    top: calc(50% - 50px);
    left: calc(50% - 50px);
  }
}

.login-spinner{
  background: -webkit-linear-gradient(var(--psr-radio-button-bg), var(--psr-text-link-pur));
  background: -moz-linear-gradient(var(--psr-radio-button-bg), var(--psr-text-link-pur));
  background: -o-linear-gradient(var(--psr-radio-button-bg), var(--psr-text-link-pur));
  background: linear-gradient(var(--psr-radio-button-bg), var(--psr-text-link-pur));
  .mat-progress-spinner circle, .mat-spinner circle { stroke: white; }
}



.mat-progress-bar.progress-bar-green {
  .mat-progress-bar-buffer{ background-color: var(--psr-input-border) !important; }
  .mat-progress-bar-fill::after {
    background-color: var(--psr-progress-bar-green) !important;
  }
}

.spinner-loading {
  width: 100% !important;
  height: 100% !important;
  svg {
    width: 100% !important;
    height: 100% !important;
    padding: 8px;
  }
}
