.page-container {
    &__content {
      max-height: calc(100vh - 370px);
      overflow-y: auto;
    }
    .items {
      width: 100%;
      flex: 0 0 100%;
    }
    .div-button {
      text-align: left;
      button {
        width: calc(100% - 60px);
      }
    }
  
    @media (min-width: 920px) {
      .items{
        width: 50%;
        flex: 0 0 50%;
      }
    }
  }
  
  .fullScreen {
    max-height: calc(100vh - 170px) !important;
  }
  
  .suspendedHeight {
    max-height: calc(100vh - 460px);
  }
  
  @media (min-width: 600px) {
    .page-container {
      &__content {
        max-height: calc(100vh - 305px);
      }
      .div-button {
        text-align: center;
        button {
          width: 300px;
        }
      }
    }
  
    .fullScreen {
      max-height: calc(100vh - 115px) !important;
    }
  
    .suspendedHeight {
      max-height: calc(100vh - 385px);
    }
  }