
.datepicker{ display: none; }

.datepickerDiv{
    position: relative;
    .datepicker {
      display: block;
      cursor: pointer;
      border-radius: $border-radius-base;
      float: left;
      font-size: 0.8125em;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border: 1px solid var(--psr-input-border);
      color: var(--psr-disabled-color);
      height: 34px;
      background-color: white;
    }
    .picker-dis{
      background-color: var(--psr-app-aside-nav-head-bg);
      border-color: var(--psr-input-border);
      color: var(--psr-disabled-color);
    }
    span{
      font-size: 0.8125em;
      display: inline-block;
      #boton{
        cursor: auto;
        height: 34px;
        background: white;
        border: 1px solid var(--psr-input-border);
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    #container{
      display: none;
      margin-top: 5px;
      position: absolute;
      background-color: white;
      padding: 20px;
      border-radius: $border-radius-base;
      font-size: 0.75em;
      font-weight: bold;
      box-shadow: 0 6px 12px rgba(0,0,0,.175);
      z-index: 2;
    }
    #container::before{
      left: 20px;
      top: -7px;
      border-right: 7px solid transparent;
      border-left: 7px solid transparent;
      border-bottom: 7px solid var(--psr-ccc);
    }
    #container::after{
      left: 21px;
      top: -6px;
      border-right: 6px solid transparent;
      border-bottom: 6px solid var(--psr-font-color-primary);
      border-left: 6px solid transparent;
    }
    #container::before, #container::after{
      position: absolute;
      display: inline-block;
      content: '';
    }

    .mat-form-field-wrapper{
      margin: 0px !important; padding: 0px !important;
      .mat-form-field-flex{
        padding-left: 10px;
        padding-top: 0px;
        align-items: center;
        .mat-form-field-infix{
          padding: 0px;
          border-top: 0px;
        }
        .mat-form-field-suffix{
          padding: 0px 5px;
          border-left: 1px solid var(--psr-input-border);
        }
      }
      .mat-form-field-underline{
        height: 0;
        .mat-form-field-ripple{ height: 0; }
      }
    }
}

.mat-datepicker-content{
  margin: 0px !important;
  .mat-calendar{ width: 50vh !important; height: auto !important; }
}

.role-inputs{
  .datepickerDiv { .datepicker { width: 150px; }}
}

.new-employee-content, .employee-personal, .modalModal{
  .datepickerDiv{
    #date-button{
      float: right;
      right: 40px;
      border-left: 1px solid var(--psr-input-border);
    }
    #container{ margin-top: 40px; }
  }
}

.modalModal{
  .datepickerDiv{ #date-button{ right: 68px; }}
}

.modalEmpl{
  .datepicker{ width: 99% !important; }
  #date-button{ right: 40px !important; }
}

.timepicker{
  display: block;
  cursor: pointer;
  border-radius: $border-radius-base;
  font-size: 0.8125em;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: 6px 0px 6px 12px;
  border: 1px solid var(--psr-input-border);
  color: var(--psr-disabled-color);
  height: 34px;
}

#containerTP{
  height: 240px;
  width: 184px;
  overflow-y: scroll;
  position: absolute;
  display: none;
  .picker__holder{
    background: #f2f2f2;
    max-height: 25em;
    border-top-width: 1px;
    border-bottom-width: 1px;
    box-shadow: 0 6px 18px 1px rgba(0, 0, 0, 0.12);
    font-size: 0.8125em;
    color: var(--psr-text-color);
    .picker__list{
      list-style: none;
      padding: 0.75em 0 4.2em;
      margin: 0;
      .picker__list-item{
        border-bottom: 1px solid var(--psr-light);
        border-top: 1px solid var(--psr-light);
        margin-bottom: -1px;
        position: relative;
        background: #ffffff;
        padding: .75em 1.25em;
        cursor: pointer;
      }
      .picker__list-item:hover{ background: #b1dcfb; border-color: #0089ec; }
    }
  }
}

.timepicker[disabled], .datepicker[disabled], span #boton[disabled]{
  cursor: not-allowed !important;
  border-color: var(--psr-disabled-color) !important;
  color: var(--psr-disabled-color) !important;
  background-color: transparent !important;
}

.brth{
  .datepickerDiv{ #container{ margin-top: 40px; } }
}

.mat-calendar-body-cell.selected > .mat-calendar-body-cell-content,
.mat-calendar-body-cell.selected:hover > .mat-calendar-body-cell-content,
.mat-calendar-body-cell.selected > .mat-calendar-body-cell-content:hover {
  background-color: #673ab7 !important;
  color: #fff !important;
}

.opened-calendar{
  .mat-form-field-wrapper{
    padding: 8px 25px;
    box-shadow: none;
    border: none;
    border-radius: 100px;
    text-align: center;
    background-color: var(--psr-ccc);
    .mat-form-field-flex {
      display: inline-flex !important;
      align-items: center;
      background-color: transparent !important;
    }
    .mat-focus-indicator{
      .mat-datepicker-toggle-default-icon{ width: 100%; height: 100%; }
    }
    .mat-form-field-infix{
      margin-left: 8px;
      border-top: 0px;
      .mat-form-field-label-wrapper {
        position: relative;
        display: block;
        overflow: visible;
        .mat-form-field-label{
          color: var(--psr-text-color);
          font-size: 13px;
          font-weight: bold;
          transform: none;
          width: auto;
          margin: 0;
        }
      }
    }
    .mat-form-field-underline{ height: 0; }
  }
}
