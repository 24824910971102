.autocomplete-field {
  .mat-form-field-infix {
    line-height: 1.125;
  }
}
.mat-datepicker-popup {
  overflow-y: auto;
  max-height: calc(100vh - 340px);
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #fff;

  .mat-datepicker-content {
    box-shadow: none !important;
  }
  .mat-datepicker-close-button {
    display: none !important;
  }
}

.mat-tab-header{ border: none; }
.mat-tab-label-content{
  font-size: 0.8125em;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--psr-text-color);
  text-align: center;
}

.mat-form-field.mat-total{
  .mat-form-field-wrapper, .mat-form-field-flex{
    height: 100% !important;
  }
  .mat-form-field-flex{
    padding: 34px 0px;
    .mat-form-field-infix{
      padding: 0px !important;
      border: none !important;
    }
  }
}

.mat-form-field-underline{ display: none; }
.mat-form-field-wrapper{
  padding-bottom: 0px;
}

mat-form-field, .calendar-range {
  .mat-form-field-infix{
    width: 100%;
    input, .mat-form-field-label, .mat-select-value-text{ font-size: 0.8125em; }
  }
}

.mat-form-field-flex{
  display: grid !important;
  background-color: whitesmoke !important;
  .mat-form-field-flex{
    padding: 8px 12px !important;
    .mat-form-field-infix{
      display: flex !important;
      align-items: center !important;
      border-top: none !important;
      padding: 0px !important;
      .form-field-span{
        line-height: 42px;
        background: var(--psr-ccc);
        width: 1px;
      }
    }
  }
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0,0,0,.02) !important;
}

.mat-form-field.background-secondary-blue {
  .mat-form-field-flex{
    background-color: var(--psr-secondary) !important;
  }
}

.mat-form-field.no-label{
  .mat-form-field-infix{
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
  }
  .mat-select-arrow-wrapper{
    height: 0px;
  }
}

.mat-form-field.flex{
  display: inline-flex !important;
  .mat-form-field-wrapper{ width: 100% !important; }
  .mat-form-field-flex{ display: flex !important; }
}

.no-border-top-left{
  .mat-form-field-flex{ border-top-left-radius: 0px !important; }
}

.no-border-top-right{
  .mat-form-field-flex{ border-top-right-radius: 0px !important; }
}

.mat-form-field.search-location {
  .mat-form-field-flex{
    padding-top: 8px;
    .mat-form-field-infix{
      padding-top: 0px;
      padding-bottom: 5px;
    }
  }
}

.datepicker .mat-form-field-flex,
.calendar-range .mat-form-field-flex{
  display: inline-flex !important;
  align-items: center !important;
}

.calendar-range{
  .mat-form-field-flex{
    .mat-date-range-input-start-wrapper{ input{ top: 3px; } }
    .mat-date-range-input-separator{ margin-right: 8px; }
  }
}

.no-border{
  .mat-form-field-infix{ border: none !important; }
}

.mat-checkbox.space-normal {
  .mat-checkbox-label{
    white-space: normal;
  }
}

.mat-form-field.background-white,
.calendar-range.background-white {
  .mat-form-field-flex {
    background-color: white !important;
  }
}

// radio-button
.mat-radio-button{
  .mat-radio-label-content{ display: flex; align-items: center; }
}
.mat-radio-button.f13, .mat-checkbox.f13{
  .mat-radio-label-content, .mat-checkbox-label{ font-size: 13px !important; }
}

.mat-radio-button.fw-bold{
  .mat-radio-label-content{ font-weight: bold !important; }
}
.mat-radio-button.purple11{
  .mat-radio-label-content{
    font-weight: bold !important;
    color: var(--psr-radio-button-bg) !important;
    text-transform: uppercase !important;
    font-size: 0.75em !important;
  }
}

.mat-radio-group.space-normal {
  .mat-radio-label-content {
    white-space: normal;
  }
}

// stepper
.mat-stepper-horizontal{
  .mat-stepper-horizontal-line{
    max-width: 120px;
  }
}

.mat-stepper-horizontal.p-0{
  .mat-horizontal-content-container{ padding: 0px; }
}


// slider-toggle

.psr-slider-toggle{
  font-size: 13px !important;
  .mat-slide-toggle-thumb-container{
    .mat-slide-toggle-thumb{
      background-color: white !important;
    }
  }
  .mat-slide-toggle-content{
    white-space: normal !important;
  }
}

.psr-slider-toggle.mat-checked .mat-slide-toggle-bar{
  background-color: var(--psr-radio-button-bg) !important;
}

.psr-slider-toggle,
.psr-slider-toggle .mat-slide-toggle-content,
.mat-radio-button.fw-normal .mat-radio-label-content,
.mat-checkbox.fw-normal .mat-checkbox-label
{
  font-weight: normal;
}


// ------------------------ TIMEPICKER ------------------------

.timepicker-backdrop-overlay, .timepicker-overlay{
  z-index: 1000 !important;
}

.timepicker-overlay{
  .timepicker{
    height: auto;
    width: auto;
    background: transparent;
    border: none;
    padding: 0px;
    &__header{
      background-color: var(--psr-radio-button-bg) !important;
      .timepicker-dial{
        &__container{
          justify-content: center !important;
        }
      }
    }
    &__actions{
      .timepicker-button{ color: var(--psr-radio-button-bg) !important; }
    }
    &__body{
      .clock-face{
        &__clock-hand, &__number > span.active{
          background-color: var(--psr-radio-button-bg) !important;
        }
        &__clock-hand_minute{
          &:before{
            border: 4px solid var(--psr-radio-button-bg) !important;
          }
        }
      }
    }
  }
}


// ------------------- ACCORDION -------------------------------

.passer-accordion {
  .mat-expansion-panel {
    @extend .shadow-box;
    @extend .radius-base;
    @extend .p-16;
    .mat-expansion-panel-content {
      @extend .f16;
    }
    .mat-expansion-panel-header {
      @extend .p-0;
      @extend .h-auto;
    }
    .mat-expansion-panel-body {
      @extend .p-t-20;
      @extend .p-x-0;
      @extend .p-b-0;
    }
  }
}

// -------------------- TIMERPICKER --------------------------------------------

.mat-form-field.timepicker-passer {
  .mat-form-field-flex {
    height: 80px;
    width: 90px;
    border-radius: 5px;
    input {
      font-size: 2em;
      text-align: center;
    }
  }
  &.mat-focused {
    .mat-form-field-flex {
      border: 1px solid var(--psr-radio-button-bg);
      background-color: var(--psr-font-color-primary) !important;
    }
  }
}