

.passer-input-login{
  position: relative;
  border: 1px solid var(--psr-input-border);
  color: #B8B8C7;
  background: var(--psr-skeleton);
  height: 53px !important;
}

.checkbox-input:checked +.checkbox.checkbox-secondary:after{ top: 3px; left: 6px; }

.n-resize{ resize: none !important; }

.passer-phone-input{
  &__code{ width: 44.5%; }
  &__separate{ width: 1%; height: 59.5px; }
  &__phone{ width: 54.5%; }
}

.emoji-icon {
  position: absolute;
  right: -8px;
  top: -8px;
  cursor: pointer;
  font-size: 18px;
}

.emoji-markers {
  position: absolute;
  right: 0px;
  top: 16px;
  z-index: 10;
}
