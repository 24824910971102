
html{
  background-color: var(--psr-primary-background);
  font-size: $font-size-base;
  min-height: 100vh;
  position: relative;
}

body{
  overflow-y: hidden;
  background-color: var(--psr-secondary-background);
  color: var(--psr-text-color) !important;
  font-family: $font-family-sans !important; //  $font-family-sans !important;
  font-size: $font-size-base !important;
  min-height: 100vh !important;
  min-width: 100vw !important;
  line-height: 1.5 !important;
  position: fixed;
}

h1,h2,h3,h4,h5,h6{ font-family: $font-family-sans !important; margin: 10px 0 !important; }
h1{ font-size: 2.25em !important; }
h2{ font-size: 1.875em !important; }
h3{ font-size: 1.5em !important; }
h4{ font-size: 1.125em !important; }
h5{ font-size: 0.875em !important; }
h6{ font-size: 0.75em !important; }

*:focus{ outline: 0 !important; }

.container{
  width: 100% !important;
  padding: 0px;
}

// SCROLLBAR CUSTOM
::-webkit-scrollbar {
  height: 15px;
  width: 12px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: gray;
}

.marg-title{ margin-left: 0px !important; }

@media screen and (max-width: 992px){
  .marg-title{ margin-left: 40px !important; }
}

.app-global-container{
  min-height: 100vh;
  position: relative;
  background: var(--psr-secondary-background);
}

.app-global-noUser{
  background: -webkit-linear-gradient(var(--psr-radio-button-bg), var(--psr-text-link-pur));
  background: -moz-linear-gradient(var(--psr-radio-button-bg), var(--psr-text-link-pur));
  background: -o-linear-gradient(var(--psr-radio-button-bg), var(--psr-text-link-pur));
  background: linear-gradient(var(--psr-radio-button-bg), var(--psr-text-link-pur));
}

// .app-global-container-mobil{ display: none; }

// @media screen and (max-width: 720px){
//   .app-global-container, .container-login{ display: none !important; }
//   .app-global-container-mobil{
//     display: block;
//     .content-list{
//       .content-empty{
//         min-height: 200px;
//         background-color: var(--psr-background-empty);
//       }
//     }
//   }
//   .modal{ display: none !important; }
// }

.app-content{ margin: 0 10px; width: 80%; }

.title-header-container{
  h5{ text-transform: uppercase; }
  h5, h6{
    color: var(--psr-text-color);
    font-family: $font-family-sans;
    font-size: 0.9375rem;
    font-weight: 600;
  }
  p{
    color: var(--psr-text-color);
    font-family: $font-family-sans;
    font-size: 0.8125rem;
  }
}
.content-header{
  background-color: var(--psr-skeleton);
  border-radius: $border-radius-base;
  box-shadow: 5px 5px 15px rgba(233,233,233,1);
  margin-bottom: 20px;
  padding: 10px 20px 30px;
  h5{ text-transform: uppercase; }
  h5, h6{
    color: var(--psr-text-color);
    font-family: $font-family-sans;
    font-size: 0.9375rem;
    font-weight: normal;
  }
  h6{ margin: 0; }
  p{
    color: var(--psr-text-color);
    font-family: $font-family-sans;
    font-size: 0.8125rem;
  }
  .title-container{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }
}

.filter-form,
.filter-form-col,
.filter-form-col-2box,
.filter-form-col-1,
.filter-form-col-2,
.filter-form-col-3,
.filter-form-col-4,
.filter-form-col-box,
.filter-form-col-2to4{
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 10px 0;
  grid-template-columns: repeat(1, 1fr);

  label{
    color: var(--psr-text-color);
    font-size: 14px;
    margin-bottom: 2px;
  }
  .time-input{ width: 145px !important; }
  .filter-item{
    padding: 8px 8px 0 0;
    .input-group-addon{
      background: var(--psr-font-color-primary);
      border-color: var(--psr-font-color-primary);
    }
    @media screen and (max-width: 600px) {
      width: 100%;
      min-width: none !important;
      max-width: none !important;
    }
  }
}

@media (min-width: 480px) {
  .filter-form-col-1{ grid-template-columns: repeat(1, 1fr); }
  .filter-form-col{ grid-template-columns: repeat(2, 1fr); }
  .filter-form-col-2{ grid-template-columns: repeat(2, 1fr); }
  .filter-form-col-3{ grid-template-columns: repeat(2, 1fr); }
  .filter-form-col-4{ grid-template-columns: repeat(2, 1fr); }
}

@media (min-width: 600px) {
  .filter-form { grid-template-columns: repeat(2, 1fr); }
  .span-filter-to-4{
    grid-column: span 2 !important;
  }
}

@media (min-width: 768px){
  .filter-form-col-2box { grid-template-columns: repeat(2, 1fr); }
  .filter-form-col{ grid-template-columns: repeat(3, 1fr); }
  .filter-form-col-3{ grid-template-columns: repeat(3, 1fr); }
  .filter-form-col-4{ grid-template-columns: repeat(3, 1fr); }
}

@media (min-width: 992px) {
  .filter-form-col{ grid-template-columns: repeat(2, 1fr); }
  .filter-form { grid-template-columns: repeat(3, 1fr); }
  .span-filter-to-4{
    grid-column: span 3 !important;;
  }
  .item-margin{
    margin-right: 30px !important;
  }
  .item-option{ text-align: left !important; }
}

@media (min-width: 1080px){
  .filter-form-col-box{ grid-template-columns: repeat(2, 1fr); }
}

@media (min-width: 1240px) {
  .filter-form { grid-template-columns: repeat(4, 1fr); }
  .span-filter-to-4{
    grid-column: span 4 !important;;
  }
}

@media (min-width: 1440px){
  .filter-form-col-4{ grid-template-columns: repeat(4, 1fr); }
  .filter-form-col-2to4{
    grid-template-columns: repeat(2, 1fr);
  }
}

.filter-form-header{ input, .select-wrapper{ border: 1px solid #eee; } }

.content-list{
  background-color: var(--psr-font-color-primary);
  border: 1px solid #F5F5F5;
  box-shadow: 5px 5px 15px var(--psr-sombra);
  width: 100%;
  .content-list-info{
    padding: 0 20px;
    .content-list-info-title{
      color: var(--psr-text-subtitle);
      font-family: $font-family-sans;
      font-size: 0.9375rem;
      font-weight: 600;
    }
    .contet-list-details{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      .contet-list-item{
        width: 30%;
        @media screen and (max-width: 991px){ width: 40%; }
        @media screen and (max-width: 560px){ width: 50%; }
        p{
          margin: 0 0 5px;
          .contet-list-subtitle, .contet-list-subtitle-text{
            color: var(--psr-text-color);
            font-family: $font-family-sans;
            font-size: 0.8125rem;
            font-weight: bold;
          }
          .contet-list-subtitle-text{ font-weight: 300; }
        }
      }
    }
  }
  .table{
    background-color: rgba(255,255,255,.87);
    border-radius: $border-radius-base;
    color: var(--psr-text-color);
    font-size: 1em !important;
    margin-bottom: 10px;
    vertical-align: middle;
    thead{ width: 100%; }
    tbody{
      // overflow-y: auto;
      // max-height: 55vh;
      display: block;
    }
    thead > tr > th{
      border: 0;
      &:first-child{ padding-left: 20px; }
    }
    tbody > tr > td{ &:first-child{ padding-left: 20px; } }
    tbody > tr{ width: 100%; }
    thead, tfoot, tbody tr{ display: table; table-layout: fixed; }
    td, th{
      border: 0;
      vertical-align: middle;
      .actions{
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        @media screen and (min-width: 1600px){ justify-content: flex-start; }
      }
      a{
        color: var(--psr-bbb);
        text-decoration: none;
        &:hover{ color: var(--psr-secondary); }
        &:first-child{ margin-right: 20px; }
      }
      .delete-user-btn{
        background: transparent;
        border: 0;
        .fa{ font-size: 2.5em; }
      }
      &.foot-title{ color: #9B64BF; font-size: 1.125rem !important; }
      &.foot-text{ color: var(--psr-text-color); font-size: 1.125rem !important; }
      .table-actions-button{
        background: transparent;
        border: 0;
        color: var(--psr-text-subtitle);
        font-size: 18px;
      }
      span.breakButtons{ color: #cecaca; font-size: 18px; }
    }
    tfoot{
      width: 100%;
      border-top: 1px solid #f5f5f5;
      tr > td{ &:first-child{ padding-left: 20px; } }
      .table-foot-text{
        h6{
          color: var(--psr-secondary);
          display: inline-block;
          font-size: 1.125rem;
          vertical-align: middle;
          strong{ color: var(--psr-text-color); margin-left: 5px; }
        }
        h6:first-child{ margin-right: 20px; }
      }
    }
    .selected-rows{
      tr:hover{
        background-color: var(--psr-box-value);
      }
    }
    .selected-rows-col{
      tr{ background-color: transparent; }
      tr:hover{
        td{
          background-color: var(--psr-box-value);
        }
      }
    }
  }
  .table.table-bottom{ margin-bottom: 10px; }
}

.table-striped>tbody>tr:nth-of-type(odd){ background-color: var(--psr-secondary-background); }

.table-responsive{
  border: 0 !important;

  @media screen and (max-width: 767px) {
    .table>tbody>tr>td,
    .table>tbody>tr>th,
    .table>tfoot>tr>td,
    .table>tfoot>tr>th,
    .table>thead>tr>td,
    .table>thead>tr>th {
      white-space: normal;
    }
  }
}

.content-container{
  background-color: var(--psr-font-color-primary);
  border: 1px solid #F5F5F5;
  border-radius: $border-radius-base;
  box-shadow: 0px 3px 6px var(--psr-light);
}

// flex row
.flex-row{
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

// anchor
a{
  color: var(--psr-secondary) !important;
  cursor: pointer;
  text-decoration: none !important;
  &.active, &:hover, &:focus{ color: #b78ed1 !important; }
}

// table
table{
  width: 100%;
  background-color: transparent;
  border-spacing: 0;
  border-collapse: collapse;
  th {
    font-size: 0.875em;
  }
  td {
    font-size: 0.8125em;
  }
  td, th{
    padding: 8px;
    border: 0;
  }
}

table.default{ tr{ border-bottom: 1px solid #222; }}
table.stripe{
  tbody{ tr:nth-child(odd){ background-color: #f5f5f5; }}
}
datepicker{ table td,th{ padding: 0!important; } }
.form-dp{
  #form-datepicker{ max-width: 200px; }
  @media screen and (max-width: 768px){ #form-datepicker{ max-width: 100%; } }
}
.form-tp{
  #timepicker{ max-width: 200px; }
  @media screen and (max-width: 768px){ #timepicker{ max-width: 100%; }}
}

.space{ padding: 15px; }

input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill{
  background-color: white !important;
}

.tabFloor{
  width: 100%;
  .tab{
    width: 218px;
    height: 50px;
    border-radius: 25px;
    background-color: white;
    box-shadow: 5px 5px 15px var(--psr-sombra);
    display: inline-block;
    span{
      color: var(--psr-text-color);
      padding: 15px;
      height: 50px;
      border-radius: 25px;
      cursor: pointer;
      display: inline-block;
      font-size: 0.8571em;
      transition: background-color 2s;
    }
    .on{ color: var(--psr-radio-button-bg); background-color: var(--psr-sec-back-dark); }
  }
}

.paginator{
  .ngx-pagination{
    margin-bottom: 10px !important;
    padding-left: 0px !important;
    a{ color: var(--psr-text-color) !important; }
  }
}

.collapse.in{ display: block !important; }

.htMenu{
  [type="checkbox"]:not(:checked), [type="checkbox"]:checked {
    position: relative !important; opacity: 1 !important;
  }
  .handsontable .htUIClearAll a, .handsontable .htUISelectAll a { font-size: 0.8125em !important; }
  .handsontable .htFiltersMenuValue .htUIMultipleSelectSearch input { height: auto !important; }
  .handsontable .htCheckboxRendererLabel { color: var(--psr-text-color) !important; }
}

.hotTable {
  .handsontable{
    th, td{
      vertical-align: middle !important;
      padding: 8px 10px !important;
    }
    .cell-zero {
      background-color: var(--bg-table-zero);
    }
  }
  .ht_clone_left{
    tbody td{ background-color: var(--psr-background-cell); }
  }
}

.passer-matrix{
  &.expand-matrix {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    padding: 8px;
    z-index: 15;
    background-color: var(--psr-font-color-primary);
  }
}

.pop-over{
  .popover-content{
    background: white;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    border: none;
    opacity: 1;
    max-width: 350px;
    .arrow{ display: none; }
  }
}

.mat-tooltip-panel{
  .mat-tooltip{
    background: white;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    border: none;
    max-width: 350px;
    font-size: 0.8125em;
    padding: 16px;
    text-align: justify;
    color: var(--psr-text-color);
  }
}

.menu-copny-header{
  max-width: 360px !important;
  min-width: 360px !important;
  box-shadow: 0px 8px 10px var(--psr-shadow-box) !important;
}

.mat-button-wrapper{ width: 30px !important; }
