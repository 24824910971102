.modal-to-print { display: none; }

.print-body {
  .text-12-8 {
    font-size: 0.75em;
  }
  .text-13-9, .text-13-11{
    font-size: 0.8125em;
  }
  .text-14-12 {
    font-size: 0.875em;
  }
  .text-10-8 {
    font-size: 0.625em;
  }
  .table-ticket {
    td {
      text-transform: none;
      padding: 0 0px 2px 8px !important;
      &:first-child {
        padding-right: 0px !important;
      }
    }
  }
}

.printing {
  .text-13-11 {
    font-size: 0.6875em;
  }
  .text-14-12 {
    font-size: 0.75em;
  }
  .text-13-9 {
    font-size: 0.5625em;
  }
  .text-12-8, .text-10-8 {
    font-size: 0.5em;
  }
}

@media print {
  @page {
    size: portrait !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  #ID-of-div-with-long-content {
    clear: both;
    min-height: 1px;
    page-break-after: always;
    break-after: always;
  }

  .alert-subscription,
  .app-global-container,
  .cdk-overlay-container,
  .cdk-live-announcer-element {
    display: none !important;
  }

  .cdk-global-scrollblock {
    overflow-y: initial;
    position: absolute;
  }

  body {
    position: static;
    line-height: normal !important;
    min-height: auto !important;
    height: auto !important;
    .modal-to-print{
      display: block;
      .print-body {
        padding: 10px !important;
        .divider-ticket, .divider-subticket {
          height: 3px !important;
          background-color: var(--psr-text-color) !important;
          margin: 6px 0px !important;
          border-top: 3px solid var(--psr-text-color) !important;
        }
        .divider-subticket {
          height: 1px !important;
          border-top: 1px solid var(--psr-text-color) !important;
          margin: 0px 0px 8px !important;
        }
      }
    }
  }
}
