.div-bus-tabs{ 
  overflow-y: auto; 
  height: calc(100vh - 400px); 
  display: flex;
}

@media screen and (orientation: landscape) {
  .div-bus-tabs {
    .current-tab.first-floor, .current-tab.second-floor {
      justify-content: flex-start;
      align-items: center;
    }
  }
}


@media screen and (orientation: landscape) and (min-width: 700px) and (max-width: 1440px){
  .div-bus-tabs {

    &.has-two-floors:not(.is-creating-modify) {
      overflow-x: auto !important;
      overflow-y: hidden !important;

        .first-floor {
          top: -100%;
          left: 0!important;
        
          &.current-tab {
            top: 0%;
          }
        }
        
        .second-floor {
          top: 100%;
          left: 0!important;
          &.current-tab {
            top: 0%;
          }
        }
    }

    &.has-two-floors.is-creating-modify {
      .first-floor {
        visibility: hidden;
      
        &.current-tab {
          visibility: visible;
        }
      }
      
      .second-floor {
        visibility: hidden;
        &.current-tab {
          visibility: visible;
        }
      }
    }

    .current-tab.first-floor, .current-tab.second-floor {
      justify-content: flex-start;
      align-items: center;
    }
  }
}


@media screen and (orientation: landscape) and (min-width: 1440px) {
  .div-bus-tabs {
    .current-tab.first-floor, .current-tab.second-floor {
      justify-content: center;
      align-items: center;
    }
  }
}

@media screen and (orientation: portrait) {
  .div-bus-tabs {
    .current-tab.first-floor, .current-tab.second-floor {
      justify-content: center;
      align-items: flex-start;
    }
  }
}


.create-modify-bus.div-bus-tabs {
  align-items: start;
  justify-content: center;
}

.items-divs-seats{
  margin-right: 8px;
  margin-bottom: 8px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  .div-dr{
    background-color: var(--psr-input-border);
    border-radius: 50%;
    &__circle-div{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      position: relative;
      text-align: center;
      color: var(--psr-text-color);
      .seat-circle{
        margin: 0px;
        color: var(--psr-text-color);
      }
    }
  }
  .pref{
    background: var(--psr-secondary);
    .seat-circle{ color: white }
  }
  #selected, .selected{
    background-color: var(--psr-selected-seat);
    .pref{ background: var(--psr-selected-seat); }
    .seat-circle{ color: white }
  }
  .locked{
    background-color: var(--psr-input-border) !important;
    .seat-circle{ color: var(--psr-text-color) !important; }
  }
}



.bus {
  width: fit-content !important;
  padding: 7px;
  border: 2px solid var(--psr-input-border);
  border-radius: 40px 40px 30px 30px;

  &__seats {
    border-top: none !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-left: 2px solid var(--psr-input-border);
    border-right: 2px solid var(--psr-input-border);
    display: flex;
    padding: 10px;

    tr{
      height: 70px;
    }

    .first-vertical-row {
      .left-side {
        border-top-left-radius: 23px;
      }

      .right-side {
        border-top-right-radius: 23px;
      }
    }

    .pre-last-vertical-row{
      .left-side {
        border-bottom-left-radius: 23px;
      }

      .right-side {
        border-bottom-right-radius: 23px;
      }
    }

    .last-vertical-row {
      transform: translateY(10px);

      td:first-child {
        border-top-left-radius: 23px;
        border-bottom-left-radius: 23px;
      }

      td:last-child {
        border-top-right-radius: 23px;
        border-bottom-right-radius: 23px;
      }
    }

    .next-left-empty-hall {
      border-top-right-radius: 23px;
      border-bottom-right-radius: 23px;
    }

    .next-right-empty-hall {
      border-top-left-radius: 23px;
      border-bottom-left-radius: 23px;
    }

    td {
      background-color: var(--psr-app-aside-nav-head-bg);

      align-self: center;
      min-width: 70px;
      height: 100%;
      text-align: center;
      line-height: 30px;
      .div-dr, .divDrP{
        position: relative;
        &__icon{
          display: none;
          cursor: pointer;
          z-index: 10;
          background: white;
          position: absolute;
          top: 6px;
          right: 6px;
          border-radius: 50%;
        }
        &__circle-div{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50px;
          height: 50px;
          background-color: var(--psr-font-color-primary);
          border-radius: 50%;
          cursor: pointer;
          .seat-circle{
            margin: 0px;
            color: var(--psr-text-color);
            font-size: 1em;
          }
        }
        &:hover{
          .div-dr__icon{ display: block; }
        }
        .pref{
          background: var(--psr-secondary);
          .seat-circle{ color: white }
        }
        .selected{
          background-color: var(--psr-radio-button-bg);
          .pref{ background: var(--psr-radio-button-bg); }
          .seat-circle{ color: white }
        }
        .validated {
          background-color: var(--psr-positiveText);
          .pref{ background: var(--psr-positiveText); }
          .seat-circle{ color: white }
        }
        .locked{
          background-color: var(--psr-input-border) !important;
          cursor: not-allowed;
          pointer-events: none;
          .seat-circle{ color: var(--psr-text-color) !important; }
        }
        .without-number {
          font-size: 0.6875em;
        }
      }

      &.hall { 
        background-color: white; 
        border: none; 
        line-height: 50px; 

        span {
          transform: rotate(270deg);
        }
      }
    }
  }

  &__driver-section {
    display: flex;
    flex-direction: column;
    
    &--legend {
      height: 60px;
      width: 100%;
      display: flex;
      align-items: center;
      padding-left: 32px;
      border-top: 2px solid var(--psr-input-border);
      border-right: 2px solid var(--psr-input-border);
      border-left: 2px solid var(--psr-input-border);
      border-top-left-radius: 40px;
      border-top-right-radius: 40px;
    }
  
    &--spot{
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
  
  
      .driver-spot {
        width: 40%;
        height: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 2px solid var(--psr-input-border);
        border-left: 2px solid var(--psr-input-border);
  
        span {
          width: 40px;
          line-height: 30px;
          height: 40px;
          display: inline-block;
          background-color: var(--psr-input-border);
          border-radius: 5px;
        }
      }
  
      .white-spot {
        width: 20%;
      }
  
      .entrance-spot {
        width: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: inherit;
        border-top: 2px solid var(--psr-input-border);
        border-bottom: 2px solid var(--psr-input-border);
      }
    }
  }

  .back-legend {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-right-radius: 40px;
    border-bottom-left-radius: 40px;
    border-right: 2px solid var(--psr-input-border);
    border-bottom: 2px solid var(--psr-input-border) !important;
    border-left: 2px solid var(--psr-input-border) !important;
    padding-bottom: 8px;
    padding-top: 10px;
  }

  &.is-horizontal {
    min-height: 260px !important;
    display: flex;
    width: fit-content !important;

    .bus__driver-section {
      display: flex;
      flex-direction: row;

      &--legend { 
        height: 100%;
        width: 60px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        border-top-left-radius: 40px;
        border-bottom-left-radius: 40px;
        border-left: 2px solid var(--psr-input-border);
        border-bottom: 2px solid var(--psr-input-border);
        border-top: 2px solid var(--psr-input-border);
        border-right: none;
        border-top-right-radius: 0;
        span {
          transform: rotate(270deg) translateX(54px);
        }
      }

      &--spot {
        height: 100%;
        width: 60px;
        display: flex;
        flex-direction: column;

        .driver-spot {
          width: inherit; 
          height: 40% !important;
          display: flex;
          align-items: center;
          justify-content: center;
          border-bottom: 2px solid var(--psr-input-border);
          border-left: 0;
          border-right: 2px solid var(--psr-input-border);
          order: 3;
    
          span {
            width: 40px;
            line-height: 30px;
            height: 40px;
            display: inline-block;
            background-color: var(--psr-input-border);
            border-radius: 5px;
          }
        }

        .white-spot {
          height: 20%;
          order: 2;
        }

        .entrance-spot {
          height: 40% !important;
          width: inherit;
          display: flex;
          justify-content: center;
          align-items: center;
          height: inherit;
          border-left: 2px solid var(--psr-input-border);
          border-right: 2px solid var(--psr-input-border);
          border-bottom: 0;
          border-top: 0;
          order: 1;
          span {
            transform: rotate(270deg);
          }
        }
      }
    }

    .bus__seats {
      border-bottom: 2px solid var(--psr-input-border) !important;
      border-top: 2px solid var(--psr-input-border) !important;
      border-left: none;
      border-right: none;

      td{
        min-width: 50px;
      } 

      .hall { 
        span {
          transform: rotate(270deg);
        }
      }

      tr{
        .even-col{
          border-top-left-radius: 23px;
        }
        .odd-col{
          border-bottom-left-radius: 23px;
        }

        .pre-last-even-col {
          border-top-right-radius: 23px;
        }

        .pre-last-odd-col {
          border-bottom-right-radius: 23px;
        }

        .last-even-col {
          border-top-left-radius: 23px;
          border-top-right-radius: 23px;
        }

        .last-odd-col{
          border-bottom-left-radius: 23px;
          border-bottom-right-radius: 23px;
        }

        .last-col{
          transform: translateX(10px);
        }

        .next-left-empty-hall {
          border-bottom-left-radius: 23px !important;
          border-bottom-right-radius: 23px !important;
          border-top-right-radius: 0px !important;
        }

        .next-right-empty-hall {
          border-top-left-radius: 23px !important;
          border-top-right-radius: 23px !important;
          border-bottom-left-radius: 0px !important;
        }

        .full-round-last-left, .full-round-last-right {
          border-radius: 23px !important;
        }
      }
    }

    .back-legend {
      display: flex;
      align-items: center;
      border-bottom-right-radius: 40px;
      border-top-right-radius: 40px;
      border-bottom-left-radius: 0px;
      border-right: 2px solid var(--psr-input-border);
      border-bottom: 2px solid var(--psr-input-border) !important;
      border-top: 2px solid var(--psr-input-border) !important;
      border-left: none !important;
      

      span {
        transform: rotate(270deg);
      }
    }
  }
}

.tickets-sale {
  .bus-container{
    display: flex;
    height: inherit;
    width: inherit;
  
  }
}