.table-ticket {
  td {
    border: none !important;
    padding: 0px 0px 4px !important;
  }
  tr td:first-child {
    padding-right: 8px !important;
  }
}

@media screen and (min-width: 480px) {
  .tickets-details-modal{
    width: 400px !important;
  }
}