// Tabs Style

tabset, .tabset{
  .nav-tabs{
    list-style: none;
    border-bottom: 0;
    display: flex;
    justify-content: flex-end;
    padding: 5px;
    .nav-item{
      list-style: none;
      margin-top: 10px;
      border-left: solid 1px var(--psr-ccc);
      &:first-child{ border-left: 0; }
      a{
        border: 0;
        color: var(--psr-ccc);
        margin-top: 0;
        padding: 0 15px;
        font-size: 0.75em !important;
        text-transform: uppercase;
        font-weight: bold;
        background: transparent;
        @media screen and (min-width: $screen-xlg){ padding: 0 25px; }
        &:hover, &.active{
          border: 0;
          color: var(--psr-primary) !important;
          text-decoration: none;
        }
      }
      a > a{
        font-size: 1em;
        @media screen and (min-width: $screen-xlg){ padding: 0; }
        &:hover{ text-decoration: none; color: var(--psr-text-subtitle) !important; }
        &.active{ text-decoration: none; color: var(--psr-text-subtitle) !important; }
      }
    }
  }
  .nav-pills{
    display: flex;
    flex-flow: row nowrap;
    list-style: none;
    padding: 0;
    .nav-item{
      margin: 0;
      border: 1px solid #eee;
      border-right: 0;
      background: transparent;
      a{
        border: 0;
        padding: 15px;
        color: var(--psr-text-color);
        margin-top: 0;
        font-size: 0.875em;
        border-radius: 0;
        height: 100%;
        text-align: center;
        display: flex;
        &.active, &:active, &:focus, &:hover{
          color: #000 !important;
          text-decoration: none;
          background: rgb(238, 238, 238);
          font-weight: 700;
          font-size: 0.875em;
        }
      }
      &:first-child{ border-radius: 4px 0 0 4px; }
      &:last-child{ border-radius: 0 4px 4px 0; border-right: 1px solid #eee;
      }
    }
  }
}

tabset.tabs-primary{
  ul.nav-tabs{
    align-items: center;
    flex-flow: row nowrap;
    justify-content: initial;
    padding: 0;
    li{
      border: 0;
      border-bottom: var(--psr-text-muted) 1px solid !important;
      cursor: pointer !important;
      margin-top: 0 !important;
      text-align: center;
      width: 50%;
      a{
        color: var(--psr-text-color) !important;
        cursor: pointer !important;
        font-size: 1rem;
        padding: 15px 10px;
        text-transform: capitalize;
      }
      &:hover{
        background-color: var(--psr-primary);
        border-bottom: var(--psr-primary) 1px solid !important;
        a{ color: #fff !important; }
      }
    }
    li.nav-item.active{
      background-color: var(--psr-primary);
      border-bottom: var(--psr-primary) 1px solid !important;
      cursor: default !important;
      a.active, a.active:hover{
        background-color: var(--psr-primary);
        color: #fff !important;
        cursor: default !important;
      }
    }
  }
}

.check-modal{
  text-align: center;
  .modal-dialog{ max-width: 100% !important; }
  .modal-title{ font-size: 0.9375rem; font-weight: 600; }
  .checked{ display: block; margin: 10px auto 0; }
  .close-btn{
    background-color: transparent;
    border: 0;
    bottom: 0;
    color: var(--psr-primary);
    font-size: 0.8125rem;
    padding: 15px 15px 5px 0;
    position: absolute;
    right: 0;
  }
  .img-check-user{ margin-bottom: 15px; }
  p{ font-size: 0.8125rem; }
}

.circle-bus{
  background: #f3f3f3;
  border-radius: 50px;
  box-shadow: 2px 2px 1px #cac3c3;
  color: #b7b7b7;
  font-size: 40px;
  height: 100px;
  line-height: 100px;
  margin: 0 auto;
  text-align: center;
  width: 100px;
}

.alert-info{
  background-color: #cfffbf;
  border-radius: $border-radius-base;
  color: #555;
  padding: 5px 10px;
}

.details-list{
  p strong{ color: var(--psr-text-color); }
  p .link-action-payment{
    color: #337ab7 !important;
    cursor: pointer;
    &:hover{ text-decoration: none; }
  }
}

$button-width: 40px;
$button-height: 40px;
$bar-thickness: 4px;
$button-pad: 12px;
$button-bar-space: 12px;
$button-ts-duration: 0.5s;

.context-menu{
  .action-button{
    .btn-action{
      display: block;
      position: relative;
      overflow: hidden;
      margin: 0;
      padding: 0;
      @include size($button-width, $button-height);
      font-size: 0;
      text-indent: -9999px;
      appearance: none;
      box-shadow: none;
      border-radius: none;
      border: none;
      cursor: pointer;
      transition: background-color $button-ts-duration;
      background: var(--psr-font-color-primary);
      span{
        display: block;
        position: absolute;
        top: 18px;
        left: $button-pad;
        right: $button-pad;
        height: $bar-thickness;
        background: var(--psr-text-title-modal);
        &:before{
          position: absolute;
          display: block;
          left: 0;
          width: 100%;
          height: $bar-thickness;
          background-color: var(--psr-text-title-modal);
          content: "";
        }
        &:before{ transform: rotate(90deg); }
      }
    }
    .btn-x{
      span{
        transition-duration: $button-ts-duration;
        transition-property: transform;
        &:before{
          transition-duration: $button-ts-duration;
          transition-property: transform;
        }
      }
    }
    .is-active{
      span{
        transform: rotate(225deg);
        &:before{ transform: rotate(90deg); }
      }
    }
  }
  .context-list{
    background: var(--psr-primary-background);
    position: absolute;
    z-index: 100;
    right: 50px;
    margin-top: 10px;
    box-shadow: 5px 5px 15px #e9e9e9;
    border-radius: $border-radius-base;
    ul{
      padding: 0;
      margin: 0;
      list-style: none;
      padding: 10px 20px;
      li{
        width: 100%;
        padding: 5px 0;
        a{
          text-decoration: none;
          display: flex;
          align-items: center;
          i{ font-size: 1.8em; min-width: 30px; }
          span{ font-size: 0.8em; }
        }
      }
    }
  }
}

.header-content-menu{
  .context-menu{
    .action-button{
      .btn-header{
        background: transparent;
        border: 0;
        color: var(--psr-text-color);
      }
    }
    .context-list{
      width: 100%;
      right: 0;
      background: none;
      box-shadow: none;
      .header-menu{
        padding: 10px 0;
        background: var(--psr-font-color-primary);
        border-radius: $border-radius-base;
        margin-right: 5px;
        box-shadow: 5px 5px 15px #ddd;
        li{
          border-bottom: #f3f3f3 solid 1px;
          .header-user{
            color: #838383 !important;
            img{ border: 1px solid #838383; }
          }
          &:last-child{ border-bottom: 0; }
        }
        .btn{
          width: 100%;
          padding: 10px;
          margin: 10px 0;
        }
      }
    }
  }
}

.text-ellipsis{
  overflow: hidden;
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.space-normal {
  white-space: normal;
}

.passer-avatar{
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

@media screen and (max-width: 1450px){
  .header-contet-menu{
    .context-menu{ .context-list{ right: 50px; } }
  }
}

.image-cropp{
  max-height: 60vh !important;
  .ngx-ic-overlay{
    background-color: var(--psr-background-opacity) !important;
  }
}
